import React from 'react';
import '../../App.css';
import OfficeCards from '../OfficeCards';
import HomeCards from '../HomeCards';
import Footer from '../Footer';


function Services() {
  return (
    <>
      <h1 className="services">SERVICES</h1>
      <OfficeCards />
      <HomeCards />
      <Footer />
    </>
    
  );
}

export default Services