import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ContactForm from '../ContactForm';


function ContactUs() {
  return (
    <>
      <ContactForm/>
      <Footer />
    </>
  );
}

export default ContactUs