// import React,  { useRef } from 'react';
import React from 'react';
import './ContactForm.css'; // Import CSS for styling
//import emailjs from '@emailjs/browser';

const ContactForm = () => {
    

    // const handleSubmit = async (e) => {
    //     e.preventDefault(); // Prevent the form from submitting normally
    
    //     // Send the form data to Formspree
    //     fetch("https://formspree.io/f/xoqolklo", {
    //       method: "POST",
    //       body: new FormData(e.target),
    //     })
    //       .then((response) => response.json())
    //       .then((data) => {
    //         if (data.ok) {
    //           // Display a success message
    //           alert("Message sent successfully!");
    //           // Optionally, clear the form fields
    //           e.target.reset();
    //         } else {
    //           // Display an error message
    //           alert("An error occurred. Please try again later.");
    //         }
    //       });
    //   };

    // const sendEmail = (e) => {
    //     // var email = document.getElementByName("name").value;
    //     // var message = email + "/n" + document.getElementByName("message").value;
    //     // concatenateText(form.current);
    //     // const email = form.current.elements.to_email.value;
    //     // var message = email + "/n" + form.current.elements.message.value
    //     // form.current.elements.message.value = message;

    //   e.preventDefault();
  
    //   emailjs.sendForm('service_cy7gxla', 'template_mtzl0rr', form.current, 'YOZS7pDv9tSqBaBIA')
    //     .then((result) => {
    //         console.log(result.text);
    //         console.log(form.current.elements.email.value);
    //         console.log("Message Sent")
    //     }, (error) => {
    //         console.log(error.text);
    //     });
    // };

    const formActionUrl = process.env.REACT_APP_FORM_ACTION_URL;
    //console.log("Form Action URL:", formActionUrl);

  return (
    <div class="contact-us">
            <div class="contact-section">
                <div class="contact-info">
                    <div><i class="fas fa-map-marker-alt"></i>42 Stamford Street, Johannesburg South, 2190, Gauteng</div>
                    <div><i class="fas fa-envelope"></i><a href="mailto:zinolwandleprojects@gmail.com" class="email-link">zinolwandleprojects@gmail.com </a></div>
                    <div><i class="fas fa-phone"></i><a href="tel:+27722416864" class="phone-link">+27 72 241 6864 </a> / <a href="tel:+27766703083" class="phone-link-2"> +27 76 670 3083 </a></div>
                    <div><i class="fas fa-clock"></i>Mon - Fri 8:00 AM to 5:00 PM</div>
                </div>
                <div class="contact-form">
                    <h2>Contact Us</h2>
                    {/* <form class="contact" ref={form} onSubmit={sendEmail}> */}
                    {/* <form class="contact" onSubmit={handleSubmit}>
                        value = {FormData.name} onChange={handleChange}
                    
                    */}
                    <form id="contact-form" action= {formActionUrl} method="POST">
                        <input type="text" name="name" id="" class="text-box" placeholder="Your Name" required />
                        <input type="email" class="text-box" name="email" id=""placeholder="Your Email" required/>
                        <textarea name="message" id="" rows="10" placeholder="Your Message" required ></textarea>
                        <input type="hidden" name="comp_name" id="" value="Zinolwandle Projects" required />
                        <input type="submit" name="submit" class="send-btn" value="Send" />

                    </form>

                </div>

            </div>
    </div>
  );
};

export default ContactForm;