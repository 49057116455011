import React from 'react';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css'; 
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Home from "./components/pages/Home";
import Services from './components/pages/Services';
import Projects from './components/pages/Projects';
import ContactUs from './components/pages/ContactUs';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact Component={Home} />
          <Route path='/services' Component={Services} />
          <Route path='/projects' Component={Projects} />
          <Route path='/contact-us' Component={ContactUs} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
