import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
          // Define an array of quotes
          const quotes = [
            '"Crafting Dreams, One Timber at a time. Where Woodwork Meets Artistry"',
            '"If you cannot find the time to do it properly, how will you find the time to fix it?"',
            '"Anyone who does not think money grows on trees has not bought any lumber lately."',
            '"Where you find quality, you will find a craftsman, not a quality-control expert."',
            '"He who works with his hands is a laborer. He who works with his hands and his head is a craftsman. He who works with his hands and his head and his heart is an artist."',
            '"For the artisan, craft is an end in itself. For you, the artist, craft is the vehicle for expressing your vision. Craft is the visible edge of art"'
        ];

        // Function to display a random quote
        // function displayRandomQuote() {
        //     // Generate a random index
        //     const randomIndex = Math.floor(Math.random() * quotes.length);

        //     // Get the quote at the random index
        //     const randomQuote = quotes[randomIndex];

        //     // Display the random quote in the HTML
        //     const quoteElement = document.getElementById("quote");
        //     quoteElement.textContent = randomQuote;
        // }

        // // Call the function when the page is loaded
        // window.addEventListener("load", displayRandomQuote);

        // // Add an event listener for page reload
        // window.addEventListener("beforeunload", function() {
        //   // Display a random quote when the page is reloaded
        //   displayRandomQuote();
        // });

  return (
    <div className='footer-container'>
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the Woodwork newsletter to receive our best Furniture deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section> */}
      <section className='footer-subscription'>
        <p className='footer-subscription-heading' id="quote">
          “Crafting Dreams, One Timber at a time. Where Woodwork Meets Artistry”
        </p>
        <p className='footer-subscription-text'>
          Let us help you achieve those goals. Get in touch with us!
        </p>
        {/* <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div> */}
      </section>

      {/* <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div> */}
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              <img className='zino-logo' src="images/Zino-logo-02.jpg"/>
            </Link>
          </div>
          <small class='website-rights'>ZINOLWANDLE PROJECTS © 2023</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='https://www.instagram.com/zinolwandle_projects?utm_source=qr'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            {/* <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link> */}
            <Link
              class='social-icon-link twitter'
              to='https://twitter.com/zinolwandle'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link whatsapp'
              to='https://wa.me/27722416864'
              target='_blank'
              aria-label='whatsapp'
            >
              <i class='fab fa-whatsapp' />
            </Link>
          </div>
        </div>
      </section>
      <small class='developer-info'>Developed By <a href="https://github.com/KayTech248" class="web-link">4ireTech</a> </small>
    </div>

  );
}

export default Footer;