import React from 'react'
import './OfficeCards.css';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
import CardItem from './CardItem';


function OfficeCards() {
  return (
    <div className= "cards">
        <h2>Office</h2>
        <div className="cards__container">
            <div className="cards__wrapper">
          <ul className='cards__items'>
            <CardItem
              src='images/Zinolwandle_Projects-Boardroom-1.jpg'
              text='Boardroom and Conference Tables'
              label='Office Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle_Projects-reception-01.jpg'
              text='Reception Units'
              label='Office Furniture'
              path='/services'
            />            
            <CardItem
              src='images/Zinolwandle-Projects-Office Furniture.jpeg'
              text='Office Furniture and Accessories'
              label='Office Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle-Projects-Filing-Storage-01.jpeg'
              text='Filing and Storage Solutions'
              label='Office Furniture'
              path='/services'
            />            
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/Zinolwandle-Projects_Credenzas_Pendenzas_Padestals.jpg'
              text='Office Credenzas, Pendenzas and Padestals'
              label='Office Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle-Projects_Cluster_Workstations.jpg'
              text='Cluster Workstations'
              label='Office Furniture'
              path='/services'
            />

            <CardItem
              src='images/Zinolwandle_Projects_office_interior_design.jpg'
              text='Office Interior Design'
              label='Office Furniture'
              path='/services'
            />                        
            <CardItem
              src='images/Zinolwandle_Projects_custom_furniture.jpg'
              text='Custom Furniture Work'
              label='Office Furniture'
              path='/services'
            />
          </ul>
            </div>
        </div>


    </div>
  )
}

export default OfficeCards