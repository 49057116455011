import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
//import vid from "../../videos/video-1.mp4";


function HeroSection() {
  return (
    <div className='hero-container'>
        {/* <video src="../../videos/video-1.mp4" autoPlay loop muted /> */}
        <h1>BUILDING YOUR DREAM FURNITURE SOLUTIONS </h1>
        <p>What are you looking for </p>
        <div class="hero-btns">
            <Button className="btns" buttonStyle="btn--outline" buttonSize="btn--large">
                GET STARTED
            </Button>
            <Button className="btns" buttonStyle="btn--primary" buttonSize="btn--large">
                CONTACT US <i className="fa-solid fa-mobile-screen-button"></i>
            </Button>
        </div>
    </div>
  );
}

export default HeroSection;