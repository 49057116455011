import React from 'react'
import './HomeCards.css';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
import CardItem from './CardItem';


function HomeCards() {
  return (
    <div className= "cards">
        <h2>Home</h2>
        <div className="cards__container">
            <div className="cards__wrapper">
          <ul className='cards__items'>
          <CardItem
              src='images/Zinolwandle_Projects-Kitchen-Units-01.jpg'
              text='Designer Kitchen Units'
              label='Home Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle_Projects-Wine-Racks-01.jpg'
              text='Wine racks'
              label='Home Furniture'
              path='/services'
            />            
            <CardItem
              src='images/Zinolwandle-Projects_Home_tv_display.jpg'
              text='Lounge and TV displays'
              label='Home Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle-Projects_home_vanities.jpg'
              text='Vanities'
              label='Home Furniture'
              path='/services'
            />            
          </ul>
          <ul className='cards__items'>
          <CardItem
              src='images/Zinolwandle_Projects-Shoe-Racks-01.jpeg'
              text='Shoe Racks'
              label='Home Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle_Projects-Built-InCupboards.jpeg'
              text='Built In Cupboards'
              label='Home Furniture'
              path='/services'
            />
          <CardItem
              src='images/Zinolwandle-Projects_home_Interior_design.jpg'
              text='Home Interior Design'
              label='Home Furniture'
              path='/services'
            /> 
            <CardItem
              src='images/Zinolwandle-Projects_home_custom_furniture.jpg'
              text='Custom Furniture Design'
              label='Home Furniture'
              path='/services'
            />           
          </ul>
            </div>
        </div>


    </div>
  )
}

export default HomeCards