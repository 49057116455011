import React from 'react'
import './Cards.css';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
import CardItem from './CardItem';


function Cards() {
  return (
    <div className= "cards">
        <h1>OUR SERVICES</h1>
        <div className="cards__container">
            <div className="cards__wrapper">
          <ul className='cards__items'>
            <CardItem
              src='images/Zinolwandle_Projects-Boardroom-1.jpg'
              text='Boardroom and Conference Tables'
              label='Office Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle_Projects-reception-01.jpg'
              text='Reception Units'
              label='Office Furniture'
              path='/services'
            />            
            <CardItem
              src='images/Zinolwandle-Projects-Office Furniture.jpeg'
              text='Office Furniture and Accessories'
              label='Office Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle-Projects-Filing-Storage-01.jpeg'
              text='Filing and Storage Solutions'
              label='Office Furniture'
              path='/services'
            />            
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/Zinolwandle_Projects-Kitchen-Units-01.jpg'
              text='Designer Kitchen Units'
              label='Home Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle_Projects-Wine-Racks-01.jpg'
              text='Wine racks'
              label='Home Furniture'
              path='/services'
            />            
            <CardItem
              src='images/Zinolwandle_Projects-Shoe-Racks-01.jpeg'
              text='Shoe Racks'
              label='Home Furniture'
              path='/services'
            />
            <CardItem
              src='images/Zinolwandle_Projects-Built-InCupboards.jpeg'
              text='Built In Cupboards'
              label='Home Furniture'
              path='/services'
            />
          </ul>
            </div>
        </div>
        <div className='see-more-link'>
              <Link className='service-link' to="/services" >
                VIEW MORE ...
              </Link>

        </div>

    </div>
  )
}

export default Cards