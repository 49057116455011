import React from 'react';
import '../../App.css';
import Footer from '../Footer';


function Projects() {
  return (
    <>
      <h1 className="projects">COMING SOON...</h1>
      <Footer />
      
    </>
    
  );
}

export default Projects